@import "./index.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTableContainer-root {
  max-height: 70vh; /* Adjust based on your screen height */
  overflow-y: auto; /* Ensure vertical scrolling */
}

table {
  width: 100%;
  display: block;
  overflow-x: auto; /* Ensure horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
}

th, td {
  min-width: 150px; /* Adjust based on your table content */
}
