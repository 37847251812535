body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color:#eeeefc;
}
a{
  text-decoration: none;
}

.MuiTableContainer-root {
  max-height: 70vh; /* Adjust based on your screen height */
  overflow-y: auto; /* Ensure vertical scrolling */
}

table {
  width: 100%;
  display: block;
  overflow-x: auto; /* Ensure horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
}

th, td {
  min-width: 150px; /* Adjust based on your table content */
}
*{
  overflow: hidden;
}